import domReady from '@wordpress/dom-ready';

domReady(() => {
  const memberContents = document.querySelectorAll('.memberContent');

  memberContents.forEach((memberContent) => {
    memberContent.addEventListener('click', () => {
      // -------------- Remove class opened on all the memberContents
      memberContents.forEach((otherContent) => {
        if (otherContent !== memberContent) {
          otherContent.classList.remove('opened');
        }
      });

      // -------------- Toggle the class on the clicked memberContent
      memberContent.classList.toggle('opened');
    });
  });
});
